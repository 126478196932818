import $ from 'jquery';

$.fn.cookies = function(options) {

    // Default options
    var settings = $.extend({
        cookieSelector: $(this),
        cookieContainerSelector: '.cookie-container',
        hideCookieClass: 'hide-cookie',
        cookiePopupStateName: 'cookie-popupState',
        cookieStatisticName: 'cookie-statistic',
        cookieMarketingName: 'cookie-marketing',
        cookiePopupState: false,
        cookieAcceptSelector: '#acceptCookies',
        cookieDeclineSelector: '#declineCookies',
        cookieSettingsSelector: '#openCookieSettings',
        cookieOpenLinks: 'a[href="#openCookieBar"]',
        cookieExpiry: 14,
        cookiePath: '/'
    }, options );

    // Hilfsfunktion zum Entfernen des Hashs aus der URL
    function removeHash(url) {
        const urlObject = new URL(url);
        urlObject.hash = "";
        return urlObject.toString();
    }

    // Löscht ein Cookie, indem ein vergangenes Ablaufdatum gesetzt wird
    function deleteCookie(name) {
        document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=' + settings.cookiePath;
    }

    // Speichert die Auswahl der Checkboxen
    function setSelectedCookies() {
        $(settings.cookieContainerSelector + ' input:checkbox:checked').each(function () {
            var value = $(this).val();
            setCookie(value, true);
        });
        $(settings.cookieContainerSelector + ' input:checkbox:not(:checked)').each(function () {
            var value = $(this).val();
            setCookie(value, false);
        });
    }

    // Setzt ein Cookie mit Ablaufdatum
    function setCookie(name, value) {
        var expires = new Date();
        expires.setTime(expires.getTime() + (settings.cookieExpiry * 24 * 60 * 60 * 1000));
        document.cookie = name + '=' + value + ';expires=' + expires.toUTCString() + ';path=' + settings.cookiePath;
    }

    // Holt den Wert eines Cookies
    function getCookie(name) {
        var value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return value ? value[2] : null;
    }

    // Zeigt/Versteckt die Cookie-Benachrichtigung
    function toggleNotice() {
        $(settings.cookieSelector).toggleClass(settings.hideCookieClass);
    }

    // Zeigt/Versteckt den Settings-Bereich und ändert den Text des Accept-Buttons
    function toggleSettings() {
        $(settings.cookieContainerSelector).toggleClass(settings.hideCookieClass);
        if (!$(settings.cookieContainerSelector).hasClass(settings.hideCookieClass)) {
            $(settings.cookieSelector).find(settings.cookieAcceptSelector).text('Save settings');
        } else {
            $(settings.cookieSelector).find(settings.cookieAcceptSelector).text('Accept');
        }
    }

    // Initialisierung des Plugins
    function init() {

        // Accept-/Speichern-Button
        $(settings.cookieSelector).find(settings.cookieAcceptSelector).on('click', function(){
            setCookie(settings.cookiePopupStateName, true);
            // Prüfe, ob der Nutzer in den Einstellungen ist (Container sichtbar)
            if (!$(settings.cookieContainerSelector).hasClass(settings.hideCookieClass)) {
                // In den Einstellungen: nur die selektierten Cookies speichern
                setSelectedCookies();
                // Nach dem Speichern: den Settings-Bereich schließen und Button-Text zurücksetzen
                $(settings.cookieContainerSelector).addClass(settings.hideCookieClass);
                $(settings.cookieSelector).find(settings.cookieAcceptSelector).text('Accept');
            } else {
                // Nicht in den Einstellungen: Es sollen alle Cookies gesetzt werden
                setCookie(settings.cookieStatisticName, true);
                setCookie(settings.cookieMarketingName, true);
            }
            toggleNotice();
            // Seite neu laden, damit die Änderungen wirksam werden
            var currentUrlWithParams = window.location.href;
            window.location.href = removeHash(currentUrlWithParams);
        });

        // Decline-Button
        $(settings.cookieSelector).find(settings.cookieDeclineSelector).on('click', function(){
            setCookie(settings.cookiePopupStateName, true);
            // Falls in den Einstellungen, sollen alle nicht notwendigen Cookies gelöscht werden
            if (!$(settings.cookieContainerSelector).hasClass(settings.hideCookieClass)) {
                deleteCookie(settings.cookieStatisticName);
                deleteCookie(settings.cookieMarketingName);
            } else {
                // Andernfalls werden sie auf false gesetzt
                setCookie(settings.cookieStatisticName, false);
                setCookie(settings.cookieMarketingName, false);
            }
            toggleNotice();
            var currentUrlWithParams = window.location.href;
            window.location.href = removeHash(currentUrlWithParams);
        });

        // Settings-Button: Öffnet oder schließt den Bereich mit den Checkboxen
        $(settings.cookieSelector).find(settings.cookieSettingsSelector).on('click', function(){
            toggleSettings();
        });

        // Öffnet die Cookie-Benachrichtigung per Link (z. B. in der Navigation)
        $('body').find(settings.cookieOpenLinks).on('click', function(){
            toggleNotice();
        });

        // Falls der Cookie-Popup-Status noch nicht gesetzt wurde, wird die Benachrichtigung angezeigt
        if(getCookie(settings.cookiePopupStateName) !== 'true') {
            toggleNotice();
        }
    }

    return init();
};
